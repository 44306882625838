import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// react-router components
import { Switch, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { MuiThemeProvider } from "material-ui/styles";

// Soft UI Dashboard PRO React components and layouts
import Signin from "layouts/authentication/sign-in";
import AddLead from "layouts/dashboards/pipeline/leads/add-lead";
import EditLead from "layouts/dashboards/pipeline/leads/edit-lead";
import AddOpportunity from "layouts/dashboards/pipeline/opportunities/add-opportunity";
import EditOpportunity from "layouts/dashboards/pipeline/opportunities/edit-opportunity";
// import RefinanceAutomation from "layouts/dashboards/tools/automations/refinanceAutomation";

// Utils
import AdminRoute from "utils/routes/AdminRoute";
import LoginRoute from "utils/routes/LoginRoute";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const user = useSelector((state) => state.user);
  const ui = useSelector((state) => state.ui);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: false });
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: true });
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <AdminRoute
            exact
            isAuthenticated={user.isAuthenticated}
            isAdmin={ui.uiAdmin}
            path={route.route}
            component={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider>
          <>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </>
            )}
            <Switch>
              {getRoutes(routes)}
              <AdminRoute
                exact
                isAuthenticated={user.isAuthenticated}
                isAdmin={ui.uiAdmin}
                path="/pipeline/leads/add"
                component={AddLead}
                key="addLead"
              />
              <AdminRoute
                exact
                isAuthenticated={user.isAuthenticated}
                isAdmin={ui.uiAdmin}
                path="/pipeline/leads/edit/:leadId"
                component={EditLead}
                key="editLead"
              />
              <AdminRoute
                exact
                isAuthenticated={user.isAuthenticated}
                isAdmin={ui.uiAdmin}
                path="/pipeline/opportunities/add"
                component={AddOpportunity}
                key="addOpportunity"
              />
              <AdminRoute
                exact
                isAuthenticated={user.isAuthenticated}
                isAdmin={ui.uiAdmin}
                path="/pipeline/opportunities/edit/:oppId"
                component={EditOpportunity}
                key="editOpportunity"
              />
              {/* <AdminRoute
                exact
                isAuthenticated={user.isAuthenticated}
                isAdmin={ui.uiAdmin}
                path="/tools/automations/refinance"
                component={RefinanceAutomation}
                key="emailAutomations"
              /> */}
              <LoginRoute
                exact
                isAuthenticated={user.isAuthenticated}
                path="/authentication/sign-in"
                component={Signin}
                key="signIn"
              />
            </Switch>
          </>
        </MuiThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
