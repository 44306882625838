// Firebase
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";

// Axios
import axios from "axios";

// React Redux
import { UI_ERROR_CLEAR } from "redux/types";

// Utils
import apiSuccess from "utils/helpers/apiSuccess";
import apiError from "utils/helpers/apiError";
import apiStart from "utils/helpers/apiStart";
import setLocalToken from "utils/helpers/setLocalToken";
import removeLocalToken from "utils/helpers/removeLocalToken";
import profileStart from "utils/helpers/profileStart";
import profileSuccess from "utils/helpers/profileSuccess";
import authStart from "utils/helpers/authStart";
import authSuccess from "utils/helpers/authSuccess";
import authRemove from "utils/helpers/authRemove";
import profileRemove from "utils/helpers/profileRemove";
import companyStart from "utils/helpers/companyStart";
import companyRemove from "utils/helpers/companyRemove";
import companySuccess from "utils/helpers/companySuccess";
import handleOppsRefresh from "utils/helpers/handleOppsRefresh";
import handleLeadsRefresh from "utils/helpers/handleLeadsRefresh";
import dataRemove from "utils/helpers/dataRemove";

const firebaseConfig = {
  apiKey: "AIzaSyAWMJgCJacOexWY2aYjpJjy5JBmW-GL52o",
  authDomain: "loana-f75fa.firebaseapp.com",
  projectId: "loana-f75fa",
  storageBucket: "loana-f75fa.appspot.com",
  messagingSenderId: "342388019128",
  appId: "1:342388019128:web:2a59921353009df7c1894d",
  measurementId: "G-82KDS1MXB3",
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
} else {
  getApp();
}

const auth = getAuth();

/* --------------------------------- User Auth, Company, and Data Actions -------------------------------*/
// Gets authenticated user
export const getUserAuth = (data) => (dispatch) => {
  authStart(dispatch, data);
  authSuccess(dispatch);
};

// Gets user company
export const getUserCompany = (companyId) => async (dispatch) => {
  apiStart(dispatch);
  return axios
    .get(`/company/${companyId}`)
    .then((res) => {
      companyStart(dispatch, res.data.message);
    })
    .then(() => {
      companySuccess(dispatch);
      apiSuccess(dispatch);
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

/* --------------------------------- User Profile Actions -------------------------------*/
// Gets user profile with redirect
export const getUserProfileWithRedirect = (redirect, history) => (dispatch) => {
  apiStart(dispatch);
  axios
    .get("/user/profile")
    .then((res) => {
      profileStart(dispatch, res.data.message);
      return res.data.message;
    })
    .then((res) => {
      dispatch(getUserCompany(res.companyId));
    })
    .then(() => {
      profileSuccess(dispatch);
      apiSuccess(dispatch);
      if (redirect) {
        history.push("/");
      }
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// Gets user profile
export const getUserProfile = () => async (dispatch) => {
  apiStart(dispatch);
  return axios
    .get("/user/profile")
    .then((res) => {
      if (typeof res.data.message !== "undefined") {
        profileStart(dispatch, res.data.message);
        profileSuccess(dispatch);
        return res.data.message;
      }
      return null;
    })
    .then((res) => {
      if (res !== null) {
        dispatch(getUserCompany(res.companyId));
        return res.companyId;
      }
      return null;
    })
    .then((id) => {
      if (id !== null && id !== "") {
        handleOppsRefresh(dispatch, id);
        handleLeadsRefresh(dispatch, id);
      }
    })
    .then(() => {
      apiSuccess(dispatch);
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

/* --------------------------------- User Authentication Actions -------------------------------*/
// Logs user out
export const logoutUser = () => async (dispatch) => {
  apiStart(dispatch);
  return signOut(auth)
    .then(() => {
      removeLocalToken();
      authRemove(dispatch);
      profileRemove(dispatch);
      companyRemove(dispatch);
      dataRemove(dispatch);
      apiSuccess(dispatch);
      dispatch({ type: UI_ERROR_CLEAR });
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// Logs user in with email and password
export const loginUserWithEmailAndPassword = (inputs) => async (dispatch) => {
  apiStart(dispatch);
  return signInWithEmailAndPassword(auth, inputs.email, inputs.password)
    .then((result) => {
      setLocalToken(result.user.accessToken);
      apiSuccess(dispatch);
      return result;
    })
    .catch((error) => {
      let message = "";
      if (error.code === "auth/invalid-email") {
        message = "Please enter a valid email address";
      } else if (error.code === "auth/user-not-found") {
        message = "We have no records of that email address";
      } else {
        message = "Something went wrong. Please contact support@loana.freshdesk.com";
      }
      apiError(dispatch, message);
      return error;
    });
};

/* ------------------------------------------------------------------------------------------*/
