import * as React from "react";
import { useSelector } from "react-redux";

// React Window for virtualized lists
import { FixedSizeList as List } from "react-window";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// Material UI
import { Box } from "@mui/material";

// SUI Components
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom Components
import OpportunityCard from "../OpportunityCard";

function setOwner(team, id) {
  const oppOwner = team.find((o) => o.uid === id);
  return oppOwner;
}

function OpportunityList(props) {
  const { labels, opps } = props;
  const { values } = breakpoints;
  const user = useSelector((state) => state.user);

  return (
    <Box
      sx={{
        width: "100%",
        height: window.innerHeight * 0.65 + 100,
        bgcolor: "#E9ECEF",
        borderRadius: 3,
        marginBottom: 10,
        marginTop: 1,
        padding: 3,
      }}
    >
      <SuiBox pb={3}>
        <SuiTypography variant="body1" color="dark" fontWeight="bold">
          {labels.title}
        </SuiTypography>
      </SuiBox>

      <List
        innerElementType="ul"
        itemData={opps}
        itemCount={opps.length}
        itemKey={() => uuidv4()}
        itemSize={window.innerWidth > values.md ? 170 : 115}
        height={window.innerHeight * 0.65}
        width={window.innerWidth > values.md ? 400 : window.innerWidth * 0.75}
      >
        {({ data, index, style }) => (
          <div
            style={{
              ...style,
            }}
            key={uuidv4()}
          >
            <OpportunityCard
              key={uuidv4()}
              badge={{ color: labels.color, label: labels.label }}
              leads={data[index].leads}
              opp={{
                ...data[index],
              }}
              owner={setOwner(user.company.team, data[index].oppOwner)}
            />
          </div>
        )}
      </List>
    </Box>
  );
}

OpportunityList.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  opps: PropTypes.arrayOf(
    PropTypes.shape({
      closedAmount: PropTypes.number,
      closedAt: PropTypes.string,
      createdAt: PropTypes.string,
      holdActive: PropTypes.bool,
      holdUntil: PropTypes.string,
      lastContacted: PropTypes.string,
      leads: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          leadId: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        })
      ),
      notes: PropTypes.arrayOf(
        PropTypes.shape({
          note: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
      oppId: PropTypes.string,
      oppOwner: PropTypes.string,
      refinanceFocus: PropTypes.string,
      refinanceJourneyActive: PropTypes.bool,
      refinanceJourneyStart: PropTypes.string,
      refinanceJourneyEnd: PropTypes.string,
      refinanceType: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
};

export default OpportunityList;
