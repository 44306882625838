// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import FormField from "components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function BasicsLeadForm(props) {
  const { values } = breakpoints;
  const { inputs, onChange } = props;

  return (
    <Accordion
      className="overflow-visible"
      style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="basics-accordion"
        id="basics-accordion-header"
        style={{ marginLeft: 15 }}
      >
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Basics
        </SuiTypography>
      </AccordionSummary>
      <SuiBox p={3}>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                name="name"
                value={inputs.name}
                label="Name"
                placeholder=""
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="email"
                value={inputs.email}
                label="email"
                placeholder=""
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="phone"
                value={inputs.phone}
                label="Phone"
                placeholder=""
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="company"
                value={inputs.company}
                label="Company"
                placeholder=""
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="companyTitle"
                value={inputs.companyTitle}
                label="Company Title"
                placeholder=""
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </SuiBox>
    </Accordion>
  );
}

BasicsLeadForm.propTypes = {
  inputs: PropTypes.shape({
    company: PropTypes.string.isRequired,
    companyTitle: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BasicsLeadForm;
