// UI reducer types
export const API_REQUEST = "API_REQUEST";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";
export const API_LOADING_CLEAR = "API_LOADING_CLEAR";
export const UI_ERROR = "UI_ERROR";
export const UI_ERROR_CLEAR = "UI_ERROR_CLEAR";
export const UI_LOADING = "UI_LOADING";
export const UI_LOADING_CLEAR = "UI_LOADING_CLEAR";
export const SET_UI_ADMIN = "SET_UI_ADMIN";
// User reducer types
export const SET_ISLOADING = "SET_ISLOADING";
export const SET_ISEMPTY = "SET_ISEMPTY";
export const SET_ISAUTHENTICATED = "SET_ISAUTHENTICATED";
export const SET_ISAUTHLOADING = "SET_ISAUTHLOADING";
export const SET_PROFILE = "SET_PROFILE";
export const SET_AUTH = "SET_AUTH";
export const SET_COMPANY = "SET_COMPANY";
export const SET_ISCOMPANYLOADING = "SET_ISCOMPANYLOADING";
// Data reducer types
export const SET_LEADS = "SET_LEADS";
export const SET_OPPS = "SET_OPPS";
export const SET_TASKS = "SET_TASKS";
export const SET_FOLLOWUPS = "SET_FOLLOWUPS";
export const SET_ISDATAREADY = "SET_ISDATAREADY";
