import { useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// DayJS
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// @mui material components
import { Grid, Stack, Card, Tooltip, CardContent } from "@mui/material";
import { Avatar } from "material-ui";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function OpportunityCard({ badge, opp, owner }) {
  dayjs.extend(relativeTime);

  const { values } = breakpoints;

  const history = useHistory();

  const handleEditRedirect = (event, id) => {
    event.preventDefault();
    // if (expanded) {
    history.push(`/pipeline/opportunities/edit/${id}`);
    // }
  };

  return window.innerWidth < values.md ? (
    <Grid item xs={12} key={uuidv4()}>
      <Card
        key={uuidv4()}
        onClick={(e) => handleEditRedirect(e, opp.oppId)}
        style={{
          borderRadius: 10,
          marginBottom: 10,
          boxShadow: "0px 0px 20px #ECEDEE",
        }}
      >
        <CardContent>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={2} align="center">
              <Avatar
                alt="User Profile Picture"
                src={owner.photoURL}
                style={{ objectFit: "cover", width: "32px", height: "32px" }}
              />
            </Grid>
            <Grid item xs={10}>
              <Stack spacing={1} my={2} justifyContent="center">
                {opp.leads
                  ? opp.leads.map((lead) => (
                      <SuiTypography
                        variant="caption"
                        textColor="text"
                        fontWeight="bold"
                        key={`opp-card-${lead.leadId}`}
                      >
                        {lead.name}
                      </SuiTypography>
                    ))
                  : null}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  ) : (
    <div key={uuidv4()}>
      <Card
        onClick={(e) => handleEditRedirect(e, opp.oppId)}
        style={{
          borderRadius: 10,
          boxShadow: "0px 0px 20px #ECEDEE",
          cursor: "pointer",
        }}
      >
        <CardContent style={{ paddingTop: 20 }}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="User Profile Picture"
                      src={owner.photoURL}
                      style={{ objectFit: "cover" }}
                    />
                    <SuiBadge
                      size="extra-small"
                      color={badge.color}
                      badgeContent={badge.label}
                      container
                    />
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Tooltip title={`Created: ${dayjs(opp.createdAt).format("MM/DD/YYYY")}`}>
                      <AccessTimeIcon color="dark" />
                    </Tooltip>
                    <Tooltip title={`Last Contacted: ${dayjs(opp.lastContacted).fromNow()}`}>
                      <AccessTimeFilledIcon color="dark" />
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              {opp.leads
                ? opp.leads.map((lead) => (
                    <SuiBox>
                      <SuiTypography
                        variant="caption"
                        textColor="text"
                        fontWeight="bold"
                        key={`opp-card-${lead.leadId}`}
                      >
                        {lead.name}
                      </SuiTypography>
                    </SuiBox>
                  ))
                : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

// Typechecking props for the Card
OpportunityCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  opp: PropTypes.shape({
    createdAt: PropTypes.string,
    lastContacted: PropTypes.string,
    leads: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        leadId: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      })
    ),
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        note: PropTypes.string,
        createdAt: PropTypes.string,
      })
    ),
    oppId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  owner: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uid: PropTypes.string,
    photoURL: PropTypes.string,
  }).isRequired,
};

export default OpportunityCard;
