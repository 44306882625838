const organizeOpps = (data) => {
  const organized = {
    opener: [],
    nudge: [],
    finalChance: [],
    lost: [],
    pending: [],
    trial: [],
    closed: [],
    consultation: [],
    import: [],
    education: [],
    complete: [],
  };
  data.forEach((opp) => organized[opp.status].push(opp));
  return organized;
};

export default organizeOpps;
