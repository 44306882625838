import { useSelector } from "react-redux";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

function Dummy() {
  const bypass = true;

  const loading = useSelector((state) => state.ui.loading);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />

      <SuiBox pt={6} pb={3}>
        <SuiTypography textTransform="capitalize" fontWeight="bold" variant="h4">
          Admin Home
        </SuiTypography>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Dummy;
