import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material UI
import { Divider, Grid, Stack } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function OpportunityHeader(props) {
  const { title, type } = props;

  const { values } = breakpoints;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={window.innerWidth > values.md ? 3 : 4}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          {type === "pitch" ? (
            <Grid item>
              <Stack direction="row" spacing={3} justifyContent="space-between">
                <SuiTypography variant="h4">{title}</SuiTypography>
                <SuiBox display="flex" alignItems="center">
                  <Link to="/pipeline/opportunities/add" className="decoration-none">
                    <SuiButton variant="gradient" buttonColor="error" size="small">
                      + new opportunity
                    </SuiButton>
                  </Link>
                </SuiBox>
              </Stack>
            </Grid>
          ) : (
            <Grid item>
              <SuiTypography variant="h4">{title}</SuiTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

OpportunityHeader.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default OpportunityHeader;
