import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR_CLEAR } from "redux/types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Utils
import handleOppsAndLeadsRefresh from "utils/helpers/handleOppsAndLeadsRefresh";

// Custom Components
import LeadsDataTable from "./components/LeadsDataTable";

function Leads() {
  const { values } = breakpoints;
  const bypass = true;

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.ui.loading);
  const leadsAll = useSelector((state) => state.data.leads);
  const user = useSelector((state) => state.user);

  const [currentLead, setCurrentLead] = useState({ basic: { name: "" }, leadId: "" });
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState({
    columns: [
      { Header: "name", accessor: "basic.name", id: "name" },
      { Header: "email", accessor: "basic.email", id: "email" },
      { Header: "phone", accessor: "basic.phone", id: "phone" },
      {
        Header: "created",
        accessor: "basic.createdAt",
        id: "createdAt",
        filter: (rows, id, filterValue) =>
          rows.filter(
            (row) =>
              new Date(row.values[id]).toISOString() >= filterValue[0] &&
              new Date(row.values[id]).toISOString() <= filterValue[1]
          ),
      },
      { Header: "actions", accessor: "actions", width: "10%", id: "actions" },
    ],
    rows: [],
  });

  const handleDeleteOpen = (event, lead) => {
    event.preventDefault();
    setCurrentLead(lead);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentLead({ basic: { name: "" }, leadId: "" });
  };

  const handleDeleteLead = () => {
    handleDeleteClose();
    dispatch(
      apiPostWithReturn("/lead/delete", {
        leadId: currentLead.leadId,
        companyId: user.company.companyId,
      })
    ).then(() => handleOppsAndLeadsRefresh(dispatch, user));
  };

  const addActions = (leads) => {
    const newLeads = [];
    leads.forEach((lead) => {
      newLeads.push({
        ...lead,
        actions: (
          <Grid container>
            <Grid item sm={6}>
              <Link to={`/pipeline/leads/edit/${lead.leadId}`}>
                <Tooltip title="Edit Lead">
                  <IconButton aria-label="edit" color="error">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            </Grid>
            <Grid item sm={6}>
              <Tooltip title="Delete Lead">
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={(e) => handleDeleteOpen(e, lead)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      });
    });
    return newLeads;
  };

  useEffect(() => handleOppsAndLeadsRefresh(dispatch, user), []);

  useEffect(() => {
    const rowsWithActions = addActions(leadsAll);
    setData((tableData) => ({
      ...tableData,
      rows: rowsWithActions,
    }));
  }, [leadsAll]);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="flex-start"
            padding={3}
          >
            <Grid item xs={12} md={6}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Leads
              </SuiTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent={window.innerWidth > values.md ? "end" : "center"}
              >
                <Link to="/pipeline/leads/add" className="decoration-none">
                  <SuiButton variant="gradient" buttonColor="error" size="small">
                    + new lead
                  </SuiButton>
                </Link>
              </Stack>
            </Grid>
          </Grid>
          {leadsAll && leadsAll.length !== 0 ? (
            <LeadsDataTable
              entriesPerPage={{
                defaultValue: 10,
                entries: [10, 25, 50],
              }}
              table={data}
              canSearch
            />
          ) : (
            <Grid container textAlign="center" padding={window.innerWidth > values.md ? 20 : 8}>
              <Grid item xs={12}>
                <SuiTypography variant="body2">No leads have been added / imported</SuiTypography>
              </Grid>
            </Grid>
          )}
        </Card>
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure you wish to delete ${currentLead.basic.name}?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will permanently delete this lead from your company&apos;s leads
              collection.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SuiButton
              variant="outlined"
              buttonColor="error"
              size="small"
              onClick={handleDeleteClose}
            >
              cancel
            </SuiButton>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              size="small"
              onClick={handleDeleteLead}
            >
              delete
            </SuiButton>
          </DialogActions>
        </Dialog>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Leads;
