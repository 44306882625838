import { SET_LEADS, SET_OPPS, SET_TASKS, SET_FOLLOWUPS, SET_ISDATAREADY } from "../types";

const initialState = {
  leads: [],
  opps: {
    new: [],
    pq: [],
    ai: [],
    docs: [],
    pa: [],
    uc: [],
    les: [],
    closed: [],
    crn: [],
    hold: [],
    dnq: [],
    lost: [],
  },
  tasks: [],
  followups: [],
  isDataReady: false,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LEADS:
      return {
        ...state,
        leads: action.payload,
      };
    case SET_OPPS:
      return {
        ...state,
        opps: action.payload,
      };
    case SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case SET_FOLLOWUPS:
      return {
        ...state,
        followups: action.payload,
      };
    case SET_ISDATAREADY:
      return {
        ...state,
        isDataReady: true,
      };
    default:
      return state;
  }
}
