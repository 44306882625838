import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import { Divider } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom Components
import BasicsLeadForm from "../add-lead/components/BasicsLeadForm";
import LeadFormActions from "../add-lead/components/LeadFormActions";

function EditLead() {
  const { values } = breakpoints;
  const { leadId } = useParams();
  const bypass = true;

  const dispatch = useDispatch();
  const history = useHistory();

  const leadsAll = useSelector((state) => state.data.leads);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);

  const [openError, setOpenError] = useState(false);

  // User and Data Redux Objects
  const user = useSelector((state) => state.user);

  // Is the Create Opp from Lead option available?
  const oppCheckAvailable = false;

  // Gets current lead from redux data
  const currentLead = leadsAll.filter((lead) => lead.leadId === leadId);

  // User Inputs for new lead
  const [basicInputs, setBasicInputs] = useState(currentLead[0].basic);

  // onChange handlers for user inputs
  const handleInputsChange = (event) => {
    event.persist();
    setBasicInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (basicInputs.name === "") {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a name for the new lead.",
      });
    }
    return valid;
  };

  // Handles user submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      lead: {
        basic: { ...basicInputs },
        leadId: currentLead[0].leadId,
        leadOwner: currentLead[0].leadOwner,
        opps: currentLead[0].opps,
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/lead/edit", submitData))
        .then((res) => {
          if (res.status === 200) {
            history.push("/pipeline/leads");
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Edit Lead
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <BasicsLeadForm inputs={basicInputs} onChange={handleInputsChange} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LeadFormActions onSubmit={handleSubmit} oppAddAvailable={oppCheckAvailable} />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default EditLead;
