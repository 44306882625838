import React from "react";
import { Route, Redirect } from "react-router-dom";

// Proptypes
import PropTypes from "prop-types";

const LoginRoute = (props) => {
  const { isAuthenticated } = props;

  return isAuthenticated ? <Redirect to="/" /> : <Route {...props} />;
};

LoginRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default LoginRoute;
