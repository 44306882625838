import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory } from "react-router-dom";

// @mui material components
import { Grid, Divider } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Schemas and helpers
import oppSchema from "utils/schemas/opp";

// Custom Components
import OpportunityForm from "./components/OpportunityForm";
import OpportunityFormActions from "./components/OpportunityFormActions";

function AddOpportunity() {
  const { values } = breakpoints;
  const bypass = true;
  const addDeleteAction = false;

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // Dispatch and History declarations
  const dispatch = useDispatch();
  const history = useHistory();

  // User, Data, UI Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);

  // User Inputs
  const [oppInputs, setOppInputs] = useState({ ...oppSchema, oppOwner: user.profile.uid });
  const [addedLeads, setAddedLeads] = useState([]);

  /* -------------------- Core Functions --------------------- */
  // onChange handlers for user inputs
  const handleInputsChange = (event, name) => {
    setOppInputs((inputs) => ({
      ...inputs,
      [name]: event.value,
    }));
  };

  // Sets leads to be displayed and added to inputs
  const handleSetLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads;
    leadArr.push(option);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // Removes specific lead from being displayed and inputs
  const handleRemoveLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads.filter((obj) => obj.leadId !== option.leadId);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (oppInputs.status === "" || oppInputs.owner === "" || oppInputs.leads.length === 0) {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a status, owner, and add one lead",
      });
    }
    return valid;
  };

  // Handles User submit action
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      opp: { ...oppInputs },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/opp/add", submitData))
        .then((res) => {
          if (res.status === 200) {
            history.push("/pipeline/opportunities");
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);
  /* ----------------------------------------------------------- */

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Add a New Opportunity
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <OpportunityForm
              inputs={oppInputs}
              user={user.profile}
              team={user.company.team}
              leads={addedLeads}
              onChange={handleInputsChange}
              onLeadsAdd={handleSetLeads}
              onLeadsRemove={handleRemoveLeads}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <OpportunityFormActions
              onSubmit={handleSubmit}
              deleteAvailable={addDeleteAction}
              status={oppInputs.status}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default AddOpportunity;
