import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "redux/actions/userActions";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import { AppBar, Toolbar, IconButton, Icon, Stack, Tooltip } from "@mui/material";
import { Avatar } from "material-ui";
import LogoutIcon from "@mui/icons-material/Logout";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Custom styles for DashboardNavbar
import styles from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

function LoanaTopNavbar({ absolute, light, isMini }) {
  const reduxDispatch = useDispatch();

  const [navbarType] = useState("sticky");
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar } = controller;
  const classes = styles({ transparentNavbar, absolute, light, isMini });

  const profile = useSelector((state) => state.user.profile);

  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });

  const handleLogout = (event) => {
    event.preventDefault();
    reduxDispatch(logoutUser());
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Icon
            className={classes.navbar_desktop_menu}
            fontSize="medium"
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SuiBox>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <IconButton
                size="small"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </SuiBox>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <Stack direction="row" spacing={3}>
                <Link to="/account/settings">
                  <Avatar
                    alt="User Profile Picture"
                    src={profile.photoURL}
                    style={{ objectFit: "cover" }}
                  />
                </Link>
                <IconButton size="small" color="inherit" onClick={handleLogout}>
                  <Tooltip title="Logout">
                    <LogoutIcon />
                  </Tooltip>
                </IconButton>
              </Stack>
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
LoanaTopNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
LoanaTopNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default LoanaTopNavbar;
