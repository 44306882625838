// Axios
import axios from "axios";

// Util Helpers
import apiSuccess from "utils/helpers/apiSuccess";
import apiError from "utils/helpers/apiError";
import apiStart from "utils/helpers/apiStart";

// API POST call with return data - takes a endpoint path and user inputs
export const apiPostWithReturn = (path, inputs) => async (dispatch) => {
  apiStart(dispatch);
  return axios
    .post(path, inputs)
    .then((res) => {
      apiSuccess(dispatch);
      return res;
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// API POST call - takes a endpoint path and user inputs
export const apiPost = (path, inputs) => (dispatch) => {
  apiStart(dispatch);
  axios
    .post(path, inputs)
    .then((res) => {
      apiSuccess(dispatch);
      return res;
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// API GET call with return data - takes a endpoint path
export const apiGetWithReturn = (path) => (dispatch) => {
  apiStart(dispatch);
  return axios
    .get(path)
    .then((res) => {
      apiSuccess(dispatch);
      return res;
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// API GET call - takes a endpoint path
export const apiGet = (path) => (dispatch) => {
  apiStart(dispatch);
  axios
    .get(path)
    .then((res) => {
      apiSuccess(dispatch);
      return res;
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};
