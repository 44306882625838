import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory, useParams } from "react-router-dom";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Dialog, DialogActions, DialogTitle, Grid, Divider, DialogContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Utils
import handleOppsAndLeadsRefresh from "utils/helpers/handleOppsAndLeadsRefresh";
import oppSchema from "utils/schemas/opp";

// Custom Components
import OpportunityForm from "../add-opportunity/components/OpportunityForm";
import OpportunityFormActions from "../add-opportunity/components/OpportunityFormActions";
import NotesArea from "../add-opportunity/components/NotesArea";
import NotesForm from "../add-opportunity/components/NotesForm";

function EditOpportunity() {
  const { values } = breakpoints;
  const bypass = true;
  const addDeleteAction = true;

  const { oppId } = useParams();

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // Dispatch and History declarations
  const dispatch = useDispatch();
  const history = useHistory();

  // User, Data, UI Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const oppsAll = useSelector((state) => state.data.opps);

  // User Inputs and triggers
  const [oppInputs, setOppInputs] = useState({ ...oppSchema, oppOwner: user.profile.uid });
  const [addedLeads, setAddedLeads] = useState([]);
  const [oppsReady, setOppsReady] = useState(false);

  /* ------------------ Add Note Dialog ------------------ */
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [newNote, setNewNote] = useState("");

  const handleCloseNoteDialog = () => setOpenNoteDialog(false);
  const handleOpenNoteDialog = () => setOpenNoteDialog(true);

  const setNotes = (notes) => {
    notes.push({
      createdAt: new Date().toISOString(),
      message: newNote,
      noteOwner: user.profile.uid,
      noteId: uuidv4(),
    });
    return notes;
  };

  const handleAddNote = (event) => {
    event.preventDefault();
    const tempNotes = oppInputs.notes;
    const submitData = {
      opp: {
        ...oppInputs,
        notes: setNotes(tempNotes),
      },
      companyId: user.company.companyId,
    };
    if (newNote !== "") {
      setOpenNoteDialog(false);
      dispatch(apiPostWithReturn("/opp/edit", submitData))
        .then((res) => {
          if (res.status === 200) {
            handleOppsAndLeadsRefresh(dispatch, user);
            setNewNote("");
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  const handleNoteChange = (event) => {
    event.persist();
    setNewNote(event.target.value);
  };
  /* ----------------------------------------------------------- */

  /* ------------------ Delete Dialog ------------------ */
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDeleteDialog = () => setOpenDelete(false);
  const handleOpenDeleteDialog = () => setOpenDelete(true);
  /* ----------------------------------------------------------- */

  /* -------------------- Core Functions --------------------- */
  // onChange handlers for user inputs
  const handleInputsChange = (event, name) => {
    setOppInputs((inputs) => ({
      ...inputs,
      [name]: event.value,
    }));
  };

  // Sets leads to be displayed and added to inputs
  const handleSetLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads;
    leadArr.push(option);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // Removes specific lead from being displayed and inputs
  const handleRemoveLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads.filter((obj) => obj.leadId !== option.leadId);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (oppInputs.status === "" || oppInputs.owner === "" || oppInputs.leads.length === 0) {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a status, owner, and add one lead",
      });
    }
    return valid;
  };

  // Handles opp submit action
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      opp: { ...oppInputs },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/opp/edit", submitData))
        .then((res) => {
          if (res.status === 200) {
            history.push("/pipeline/opportunities");
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Handles opp delete action
  const handleDelete = async (event) => {
    event.preventDefault();
    const submitData = {
      oppId,
      companyId: user.company.companyId,
    };
    dispatch(apiPostWithReturn("/opp/delete", submitData))
      .then((res) => {
        if (res.status === 200) {
          history.push("/pipeline/opportunities");
        }
      })
      .catch((error) => {
        dispatch({
          type: UI_ERROR,
          payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
        });
      });
  };

  // Gets all opps and leads to ensure Redux data is loaded
  useEffect(() => handleOppsAndLeadsRefresh(dispatch, user).then(() => setOppsReady(true)), []);

  // Gets current opportunity from redux data
  useEffect(() => {
    if (oppsReady) {
      const combineOppsArrs = Object.keys(oppsAll).map((key) => oppsAll[key]);
      combineOppsArrs.forEach((arr) => {
        arr.forEach((opp) => {
          if (opp.oppId === oppId) {
            setOppInputs(opp);
            setAddedLeads(opp.leads);
          }
        });
      });
    }
  }, [oppsReady]);

  // Opens error alert
  useEffect(() => {
    if (inputError !== "" && inputError !== "Email and subject cannot be blank.")
      setOpenError(true);
  }, [inputError]);
  /* ----------------------------------------------------------- */

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Edit Opportunity
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={8}>
            <OpportunityForm
              inputs={oppInputs}
              user={user.profile}
              team={user.company.team}
              leads={addedLeads}
              onChange={handleInputsChange}
              onLeadsAdd={handleSetLeads}
              onLeadsRemove={handleRemoveLeads}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OpportunityFormActions
                  onSubmit={handleSubmit}
                  onDelete={handleOpenDeleteDialog}
                  deleteAvailable={addDeleteAction}
                  status={oppInputs.status}
                />
              </Grid>
              <Grid item xs={12}>
                <NotesArea
                  notes={
                    oppInputs.notes && oppInputs.notes.length !== 0
                      ? oppInputs.notes.sort((a, b) => (a.createdAt - b.createdAt ? 1 : -1))
                      : []
                  }
                  openDialog={handleOpenNoteDialog}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      {/* DELETE DIALOG */}
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Opportunity?</DialogTitle>
        <DialogContent>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            mt={2}
          >
            <SuiTypography variant="body2" textColor="text">
              Are you sure you wish to delete this opportunity? This action cannot be undone.
            </SuiTypography>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiButton
            variant="outlined"
            buttonColor="error"
            size="small"
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </SuiButton>
          <SuiButton variant="gradient" buttonColor="error" size="small" onClick={handleDelete}>
            Delete Opportunity
          </SuiButton>
        </DialogActions>
      </Dialog>
      {/* ADD NOTE */}
      <NotesForm
        open={openNoteDialog}
        newNote={newNote}
        onClick={handleAddNote}
        onClose={handleCloseNoteDialog}
        onChange={handleNoteChange}
      />
    </DashboardLayout>
  );
}

export default EditOpportunity;
