import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory } from "react-router-dom";

// dayjs
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import { Divider } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Schemas and helpers
import oppSchema from "utils/schemas/opp";

// Custom Components
import BasicsLeadForm from "./components/BasicsLeadForm";
import LeadFormActions from "./components/LeadFormActions";

function AddLead() {
  const { values } = breakpoints;
  const bypass = true;

  const dispatch = useDispatch();
  const history = useHistory();

  // User, UI, and Data Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const user = useSelector((state) => state.user);

  const [openError, setOpenError] = useState(false);

  // Is the Create Opp from Lead option available?
  const oppCheckAvailable = true;

  // User Input to create opp from lead
  const [oppCheck, setOppCheck] = useState(false);

  // User Inputs for new lead
  const [basicInputs, setBasicInputs] = useState({
    company: "",
    companyTitle: "",
    createdAt: new Date().toISOString(),
    email: "",
    name: "",
    phone: "",
  });

  // onChange handlers for user inputs
  const handleInputsChange = (event) => {
    event.persist();
    setBasicInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOppCheck = (value) => {
    setOppCheck(value);
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (basicInputs.name === "") {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a name for the new lead.",
      });
    }
    return valid;
  };

  // User submits a new lead and wants to create an opp from the lead
  const handleLeadAndOppAdd = async (submitData) => {
    try {
      const addLead = await dispatch(apiPostWithReturn("/lead/add", submitData)).then((res) => ({
        message: res.data.message,
        type: "lead",
        code: res.data.code,
      }));

      const addOpp = await dispatch(
        apiPostWithReturn("/opp/add", {
          opp: {
            ...oppSchema,
            oppOwner: user.profile.uid,
            leads: [
              {
                leadId: addLead.message,
                name: submitData.lead.basic.name,
                phone: submitData.lead.basic.phone,
                email: submitData.lead.basic.email,
                createdAt: dayjs(submitData.lead.basic.createdAt).format("MM/DD/YYYY"),
              },
            ],
          },
          companyId: submitData.companyId,
        })
      ).then((res) => ({ message: res.data.message, type: "opp", code: res.data.code }));

      return Promise.all([addLead, addOpp]).then((results) => results);
    } catch (error) {
      dispatch({
        type: UI_ERROR,
        payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
      });
      return error;
    }
  };

  // Handles user submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      lead: {
        basic: { ...basicInputs },
        leadId: "",
        leadOwner: user.profile.uid,
        opps: [],
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      if (oppCheck) {
        handleLeadAndOppAdd(submitData).then((res) => {
          const resultsArr = res.filter((obj) => obj.type === "opp");
          history.push(`/pipeline/opportunities/edit/${resultsArr[0].message}`);
        });
      } else {
        dispatch(apiPostWithReturn("/lead/add", submitData))
          .then((response) => {
            if (response.status === 200) {
              history.push("/pipeline/leads");
            }
          })
          .catch((error) => {
            dispatch({
              type: UI_ERROR,
              payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
            });
          });
      }
    }
  };

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Add a New Lead
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <BasicsLeadForm inputs={basicInputs} onChange={handleInputsChange} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LeadFormActions
              onSubmit={handleSubmit}
              onCheck={handleOppCheck}
              oppAddAvailable={oppCheckAvailable}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default AddLead;
