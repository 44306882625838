module.exports = {
  opener: { title: "Opener", status: "opener", color: "dark", label: "opener" },
  nudge: { title: "Nudge", status: "nudge", color: "secondary", label: "nudge" },
  finalChance: {
    title: "Final Chance",
    status: "finalChance",
    color: "primary",
    label: "finalChance",
  },
  pending: {
    title: "Pending",
    status: "pending",
    color: "info",
    label: "pending",
  },
  trial: { title: "Trial", status: "trial", color: "primary", label: "trial" },
  consultation: {
    title: "Consultation",
    status: "consultation",
    color: "warning",
    label: "consultation",
  },
  import: {
    title: "Data Import",
    status: "import",
    color: "secondary",
    label: "import",
  },
  closed: { title: "Closed", status: "closed", color: "success", label: "closed" },
  education: { title: "Education", status: "education", color: "light", label: "education" },
  lost: { title: "Lost", status: "lost", color: "error", label: "lost" },
  complete: { title: "Complete", status: "complete", color: "dark", label: "complete" },
};
