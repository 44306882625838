/* eslint-disable react/jsx-props-no-spreading */

import { useState, forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";

// @material-ui core components
import useAutocomplete from "@mui/material/useAutocomplete";
import Icon from "@mui/material/Icon";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";

// Custom styles for SuiInput
import styles from "components/SuiInput/styles";
import { useSelector } from "react-redux";

const Listbox = styled("ul")(
  () => `
  width: 400px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #FFFFFF;
  overflow: auto;
  max-height: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #ECEDEE;
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    &:hover {
      background-color: #e9ecef;
    }
  }
`
);

const LoanaSearchbar = forwardRef(
  ({ size, withIcon, error, success, customClass, disabled, onLeadsAdd, ...rest }, ref) => {
    const options = useSelector((state) => state.data.leads);

    const classes = styles({ size, error, success, withIcon, disabled });

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
      useAutocomplete({
        id: "loana-searchbar",
        options,
        getOptionLabel: (option) => option.basic.name,
      });

    const [searchInput, setSearchInput] = useState("");

    const handleKeyDown = (event, option) => {
      event.preventDefault();
      if (event.key === "Enter") {
        onLeadsAdd(event, {
          createdAt: option.basic.createdAt,
          email: option.basic.email,
          leadId: option.leadId,
          name: option.basic.name,
          phone: option.basic.phone,
        });
      }
    };

    const handleInputChange = (event) => {
      event.persist();
      setSearchInput(event.target.value);
    };

    return (
      <SuiBox pr={1}>
        <SuiBox {...getRootProps()} ref={ref} customClass={clsx(classes.suiInputIcon, customClass)}>
          <SuiBox customClass={classes.suiInputIcon_right}>
            <Icon className={classes.suiInputIcon_icon} fontSize="small">
              search
            </Icon>
          </SuiBox>
          <InputBase
            {...rest}
            value={searchInput}
            inputProps={{ ...getInputProps() }}
            onChange={(e) => handleInputChange(e)}
            placeholder="Type here..."
            className={clsx(classes.suiInput, classes.suiInputIcon_input, {
              [classes.suiInput_error]: error,
              [classes.suiInput_success]: success,
              [classes[`suiInput_${size}`]]: size,
            })}
            classes={{
              focused: classes.suiInput_focused,
              disabled: classes.suiInput_disabled,
              error: classes.suiInput_error,
              multiline: classes.suiInput_multiline,
            }}
          />
        </SuiBox>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })} key={uuidv4()}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  textColor="secondary"
                  onClick={(e) =>
                    onLeadsAdd(e, {
                      createdAt: option.basic.createdAt,
                      email: option.basic.email,
                      leadId: option.leadId,
                      name: option.basic.name,
                      phone: option.basic.phone,
                    })
                  }
                  onKeyDown={(e) => handleKeyDown(e, option)}
                >
                  {option.basic.name}
                </SuiTypography>
              </li>
            ))}
          </Listbox>
        ) : null}
      </SuiBox>
    );
  }
);

// Setting default values for the props of LoanaSearchbar
LoanaSearchbar.defaultProps = {
  size: "medium",
  withIcon: {
    icon: false,
  },
  error: false,
  success: false,
  customClass: "",
  disabled: false,
};

LoanaSearchbar.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  withIcon: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  onLeadsAdd: PropTypes.func.isRequired,
};

export default LoanaSearchbar;
