import { apiGetWithReturn } from "redux/actions/dataActions";

// Utils
import oppsRefresh from "utils/helpers/oppsRefresh";
import leadsRefresh from "utils/helpers/leadsRefresh";
import organizeOpps from "./organizeOpps";

const handleOppsAndLeadsRefresh = (dispatch, user) => {
  try {
    return dispatch(apiGetWithReturn(`/opp/all/${user.company.companyId}`))
      .then((res) => {
        // Updates the Redux store with the refreshed opps
        const organizedData = organizeOpps(res.data.message);
        oppsRefresh(dispatch, organizedData);
        return res.data.message;
      })
      .then(() => {
        dispatch(apiGetWithReturn(`/lead/all/${user.company.companyId}`)).then((res) => {
          leadsRefresh(dispatch, res.data.message);
          return res.data.message;
        });
      });
  } catch (error) {
    return error;
  }
};

export default handleOppsAndLeadsRefresh;
