import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Day js
import dayjs from "dayjs";

// @mui material components
import { Card, Divider, Tooltip, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import LoanaSearchbar from "components/LoanaSearchbar";

// Custom Components

function OpportunityForm(props) {
  const { onChange, user, team, onLeadsAdd, onLeadsRemove, leads, inputs } = props;

  const { values } = breakpoints;

  const [ownerSelect, setOwnerSelect] = useState([{ value: "new", label: "new" }]);

  const options = {
    status: [
      { value: "opener", label: "Opener" },
      { value: "nudge", label: "Nudge" },
      { value: "finalChance", label: "Final Chance" },
      { value: "lost", label: "Lost" },
      { value: "pending", label: "Pending" },
      { value: "trial", label: "Trial" },
      { value: "closed", label: "Closed" },
      { value: "consultation", label: "Consultation" },
      { value: "import", label: "Data Import" },
      { value: "education", label: "Loana 101" },
    ],
    oppOwner: ownerSelect,
  };

  useEffect(() => {
    const tempArr = [];
    team.forEach((member) => {
      tempArr.push({ value: member.uid, label: `${member.firstName} ${member.lastName}` });
    });
    setOwnerSelect(tempArr);
  }, [user]);

  return (
    <Card>
      <SuiBox pl={3} pt={3} pb={1} pr={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h5" fontWeight="medium" textColor="info">
          Basics
        </SuiTypography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Opportunity Status
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.status === ""
                    ? ""
                    : options.status.filter((option) => option.value === inputs.status)
                }
                placeholder=""
                options={options.status}
                onChange={(e) => onChange(e, "status")}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Opportunity Owner
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.oppOwner === ""
                    ? ""
                    : options.oppOwner.filter((option) => option.value === inputs.oppOwner)
                }
                placeholder={`${user.firstName} ${user.lastName}`}
                options={options.oppOwner}
                onChange={(e) => onChange(e, "oppOwner")}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox pl={3} pt={1} pb={1} pr={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h5" fontWeight="medium" textColor="info">
          Leads
        </SuiTypography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LoanaSearchbar onLeadsAdd={onLeadsAdd} />
          </Grid>
          <Grid item xs={12} align="center">
            <SuiBox pl={3} pt={1} pr={3}>
              <Grid container align="start">
                {window.innerWidth > values.md ? (
                  <>
                    <Grid item xs={4}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Name
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Email
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Phone
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Created
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={1}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Actions
                      </SuiTypography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </SuiBox>
            {leads && leads.length !== 0 ? (
              leads.map((lead, index) =>
                window.innerWidth > values.md ? (
                  <SuiBox pl={3} pt={1} pb={1} pr={3} key={lead.leadId}>
                    <Link to={`/pipeline/leads/edit/${lead.leadId}`}>
                      <Grid container align="start">
                        {index === 0 ? (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        ) : null}
                        <Grid item xs={4}>
                          <SuiTypography variant="body2">{lead.name}</SuiTypography>
                        </Grid>
                        <Grid item xs={3}>
                          <SuiTypography variant="body2">{lead.email}</SuiTypography>
                        </Grid>
                        <Grid item xs={2}>
                          <SuiTypography variant="body2">{lead.phone}</SuiTypography>
                        </Grid>
                        <Grid item xs={2}>
                          <SuiTypography variant="body2">
                            {dayjs(lead.createdAt).format("MM/DD/YYYY")}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Remove Lead">
                            <IconButton
                              aria-label="remove lead"
                              color="error"
                              onClick={(e) => onLeadsRemove(e, lead)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Link>
                  </SuiBox>
                ) : (
                  <SuiBox pl={3} pt={1} pb={1} pr={3} key={lead.leadId}>
                    <Link to={`/pipeline/leads/edit/${lead.leadId}`}>
                      <Grid container alignItems="center">
                        {index === 0 ? (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        ) : null}
                        <Grid item xs={11} align="start">
                          <SuiTypography variant="body2">{lead.name}</SuiTypography>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Remove Lead">
                            <IconButton
                              aria-label="remove lead"
                              color="error"
                              onClick={(e) => onLeadsRemove(e, lead)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Link>
                  </SuiBox>
                )
              )
            ) : (
              <SuiBox p={5}>
                <SuiTypography variant="body2">
                  No leads have been added to this opportunity
                </SuiTypography>
              </SuiBox>
            )}
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

OpportunityForm.defaultProps = {
  leads: [],
  inputs: {},
};

OpportunityForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLeadsAdd: PropTypes.func.isRequired,
  onLeadsRemove: PropTypes.func.isRequired,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      leadId: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uid: PropTypes.string,
    proActive: PropTypes.bool,
  }).isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      uid: PropTypes.string,
    })
  ).isRequired,
  inputs: PropTypes.shape({
    status: PropTypes.string,
    oppOwner: PropTypes.string,
  }),
};

export default OpportunityForm;
