module.exports = {
  closedAmount: 0,
  closedAt: "",
  createdAt: new Date().toISOString(),
  creditRepairActive: false,
  creditRepairUntil: "",
  dnq: false,
  email: [],
  followUpActive: false,
  holdActive: false,
  holdUntil: "",
  lastContacted: new Date().toISOString(),
  leads: [],
  newToClosed: "",
  noResponseActive: false,
  noResponseUntil: "",
  notes: [], // {createdAt, message, noteOwner}
  oppId: "",
  oppOwner: "",
  referralSource: "",
  refinanceFocus: "", // OPTIONS - conventional, fha, usda, va
  refinanceJourneyActive: false,
  refinanceJourneyStatus: "",
  refinanceType: "", // OPTIONS - cash, rate
  sms: [],
  status: "opener", // OPTIONS - opener, nudge, finalChance, lost, pending, trial, closed, consultation, import, education, complete
  type: "", // OPTIONS - buying, refinancing
};
