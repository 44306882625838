import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Custom styles for the LayoutContainer
import styles from "examples/LayoutContainers/DashboardLayout/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

// Custom Components
import MuiLoader from "components/MuiLoader";

function LayoutContainer({ children, loading, bypass }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction } = controller;
  const { pathname } = useLocation();
  const classes = styles({ miniSidenav, direction });

  const profileloading = useSelector((state) => state.user.isLoading);
  const companyLoading = useSelector((state) => state.user.isCompanyLoading);

  const [openLoader, setOpenLoader] = useState(loading);

  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "dashboard" });
  }, [pathname]);

  useEffect(() => {
    if (!bypass) {
      if (!profileloading && !companyLoading) {
        setOpenLoader(false);
      } else {
        setOpenLoader(true);
      }
    }
  }, [profileloading, companyLoading]);

  useEffect(() => {
    if (bypass) {
      setOpenLoader(loading);
    }
  }, [loading]);

  return (
    <>
      <MuiLoader loading={openLoader} />
      <SuiBox customClass={classes.layoutContainer}>{children}</SuiBox>
    </>
  );
}

// Typechecking props for the LayoutContainer
LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  bypass: PropTypes.bool.isRequired,
};

export default LayoutContainer;
