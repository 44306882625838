import { useEffect, useState } from "react";

// react-router-dom components
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";
import { loginUserWithEmailAndPassword } from "redux/actions/userActions";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
import MuiLoader from "components/MuiLoader";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curvedAdmin from "assets/images/curvedAdmin.jpg";

function Signin() {
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const [openError, setOpenError] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleInputChange = (event) => {
    event.persist();
    setInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  const validateInputs = () => {
    let validated = true;
    if (inputs.email === "" || inputs.password === "") {
      validated = false;
      dispatch({ type: UI_ERROR, payload: "Please enter a valid email and password." });
    }
    return validated;
  };

  const handleUserEmailPass = () => {
    const validated = validateInputs();
    if (validated) {
      dispatch(loginUserWithEmailAndPassword(inputs)).then((res) => {
        if (res.status === 200) {
          history.push("/");
        }
      });
    }
  };

  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <>
      <MuiLoader loading={loading} />
      <CoverLayout
        title="Loana Admin"
        description="Sign in with an email/password"
        image={curvedAdmin}
        top={12}
      >
        {openError ? (
          <SuiAlert color="warning">
            <SuiTypography variant="body2" textColor="white">
              <SuiTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="medium"
                textColor="white"
              >
                Oops!&nbsp;
              </SuiTypography>
              {inputError}
            </SuiTypography>
          </SuiAlert>
        ) : null}
        <SuiBox component="form" role="form">
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Email
              </SuiTypography>
            </SuiBox>
            <SuiInput type="email" placeholder="Email" name="email" onChange={handleInputChange} />
          </SuiBox>
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Password
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleInputChange}
            />
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              fullWidth
              onClick={handleUserEmailPass}
            >
              sign in
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </CoverLayout>
    </>
  );
}

export default Signin;
