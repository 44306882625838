/** 
  All of the routes for the Soft UI Dashboard PRO Material are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  11. The `component` key is used to store the component of its route.
  12. The `pro` key is used to show if the route is a Loana Pro feature.
*/

// Soft UI Dashboard PRO React icons
import SpaceShip from "examples/Icons/SpaceShip";
import Shop from "examples/Icons/Shop";

// Material UI Icons
import {
  People,
  // Check,
  // PrecisionManufacturing,
  // NotificationsActive,
  // AttachMoney,
} from "@mui/icons-material";

// Layouts
import Leads from "layouts/dashboards/pipeline/leads";
import Opportunities from "layouts/dashboards/pipeline/opportunities";
// import Tasks from "layouts/dashboards/tools/tasks";
// import Followups from "layouts/dashboards/tools/followups";
// import Automations from "layouts/dashboards/tools/automations";
// import Refinance from "layouts/dashboards/pipeline/refinance";
import Dummy from "layouts/dummy";

const routes = [
  {
    type: "collapse",
    admin: false,
    name: "Home",
    key: "home",
    route: "/",
    component: Dummy,
    icon: <Shop size="12px" />,
    noCollapse: true,
    pro: false,
  },
  { type: "title", title: "Pipeline", key: "title-pipeline" },
  {
    type: "collapse",
    admin: false,
    name: "Leads",
    key: "leads",
    route: "/pipeline/leads",
    component: Leads,
    icon: <People size="12px" />,
    noCollapse: true,
    pro: false,
  },
  {
    type: "collapse",
    admin: false,
    name: "Opportunities",
    key: "opportunities",
    component: Opportunities,
    route: "/pipeline/opportunities",
    icon: <SpaceShip size="12px" />,
    noCollapse: true,
    pro: false,
  },
  // {
  //   type: "collapse",
  //   admin: false,
  //   name: "Refinance",
  //   key: "refinanceJourney",
  //   component: Refinance,
  //   route: "/pipeline/refinance",
  //   icon: <AttachMoney size="12px" />,
  //   noCollapse: true,
  //   pro: true,
  // },
  { type: "title", title: "Tools", key: "title-tools" },
  // {
  //   type: "collapse",
  //   admin: false,
  //   name: "Tasks",
  //   key: "tasks",
  //   component: Tasks,
  //   route: "/tools/tasks",
  //   icon: <Check size="12px" />,
  //   noCollapse: true,
  //   pro: true,
  // },
  // {
  //   type: "collapse",
  //   admin: false,
  //   name: "Follow-ups",
  //   key: "followups",
  //   component: Followups,
  //   route: "/tools/followups",
  //   icon: <NotificationsActive size="12px" />,
  //   noCollapse: true,
  //   pro: true,
  // },
  // {
  //   type: "collapse",
  //   admin: false,
  //   name: "Automations",
  //   key: "automations",
  //   component: Automations,
  //   route: "/tools/automations",
  //   icon: <PrecisionManufacturing size="12px" />,
  //   noCollapse: true,
  //   pro: true,
  // },
];

export default routes;
