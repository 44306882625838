import React from "react";
import { Route } from "react-router-dom";

// Proptypes
import PropTypes from "prop-types";

const AdminRoute = (props) => {
  const { isAuthenticated, isAdmin } = props;

  const redirectToClient = () => {
    window.location.href = "https://dashboard.helloloana.com/";
  };

  return isAuthenticated && isAdmin ? <Route {...props} /> : redirectToClient();
};

AdminRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default AdminRoute;
