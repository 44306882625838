import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR_CLEAR } from "redux/types";

// SUI
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Utils
import handleOppsAndLeadsRefresh from "utils/helpers/handleOppsAndLeadsRefresh";
import pipelineBoard from "utils/schemas/pipelineBoard";

// Custom components
import OpportunityList from "./components/OpportunityList";
import OpportunityHeader from "./components/OpportunityHeader";

function Opportunities() {
  const bypass = true;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const opps = useSelector((state) => state.data.opps);
  const loading = useSelector((state) => state.ui.loading);

  /* ----------------------- Core ------------------------- */
  // Refreshes boards when Redux data is loaded
  useEffect(() => handleOppsAndLeadsRefresh(dispatch, user), []);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);
  /* ----------------------------------------------------------- */

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader title="Pitch Pipeline" type="pitch" />
      </SuiBox>
      <SuiBox style={{ display: "flex", overflowX: "scroll" }}>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.opener} labels={pipelineBoard.opener} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.nudge} labels={pipelineBoard.nudge} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.finalChance} labels={pipelineBoard.finalChance} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.lost} labels={pipelineBoard.lost} />
        </SuiBox>
      </SuiBox>
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader title="Prospect Pipeline" type="prospect" />
      </SuiBox>
      <SuiBox style={{ display: "flex", overflowX: "scroll" }}>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.pending} labels={pipelineBoard.pending} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.trial} labels={pipelineBoard.trial} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.closed} labels={pipelineBoard.closed} />
        </SuiBox>
      </SuiBox>
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader title="Onboarding Pipeline" type="onboarding" />
      </SuiBox>
      <SuiBox style={{ display: "flex", overflowX: "scroll" }}>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.consultation} labels={pipelineBoard.consultation} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.import} labels={pipelineBoard.import} />
        </SuiBox>
        <SuiBox mr={2}>
          <OpportunityList opps={opps.education} labels={pipelineBoard.education} />
        </SuiBox>
        <SuiBox>
          <OpportunityList opps={opps.complete} labels={pipelineBoard.complete} />
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Opportunities;
